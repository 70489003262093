.ant-dropdown-menu {
  border-radius: 13px;
  border-radius: 13px;

}
.ant-dropdown-menu-title-content {
  color: #4f537b;
  font-family: "Mont-Regular";
  font-size: 14px;
  width: 150px;
}
.ant-dropdown-menu-item {
  height: 48px;
}

@primary-color: #1DA57A;