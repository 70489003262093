@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.less";
body {
  margin: 0;
  font-family: "montFamily", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  --antd-wave-shadow-color: #5b9dff;
  --scroll-bar: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-dropdown-menu {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  max-height: 200px;
  overflow-y: scroll;
}

.ant-dropdown-menu-title-content {
  color: #4f537b;
  font-family: "Mont-Regular";
  font-size: 14px;
}
.ant-dropdown-menu-item {
  height: 48px;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #e5e5e5;
  box-shadow: 0 0 0 0px rgb(29 165 122 / 20%);
  border-right-width: 1px;
  outline: 0;
}
.ant-input:hover {
  border-color: #e5e5e5;
  border-right-width: 1px;
}

@primary-color: #1DA57A;